var baseURL = window.location.href.split("/")
var domain = window.location.host.split('/')[0]


baseURL = baseURL[0] + "/" + baseURL[1] + "/" + baseURL[2]

var generalConstants = {
  //SERVER_URL: 'http://microw-courses.com'
  //SERVER_URL: 'http://v2test.quasaranytime.co.uk'
  SERVER_URL: baseURL,
  SERVER_DOMAIN: 'microw-courses.com' //Change to testv2 for SSO to work on Testv2, and custom domains pointed at testv2
};

if(domain.includes(":")){
  //generalConstants.SERVER_URL = 'http://localhost:3000';
  //generalConstants.SERVER_URL = 'https://dev.microw-courses.com';
  //generalConstants.SERVER_URL = 'https://testv2.microw-courses.com';
  generalConstants.SERVER_URL = 'https://microw-courses.com';
}

export { generalConstants }
