//Concatenates lists from a specific point in a defined json structure
export function filterWhere(conditions, arr){
  var newArr
  try {
    var keys = Object.keys(conditions)
    newArr = arr.filter((i) =>
      keys.filter((c) =>
        //c in i &&
        conditions[c].toString() !== i[c].toString()
      ).length === 0
    );
  
  }
  catch(err) {
    console.log('ERROR filterWhere', err, conditions, arr)
  }

  return newArr;
}
